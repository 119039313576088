
import PropTypes from "prop-types"
import React from "react"
import Navigation from './navigation'

const Header = () => (
  <header className="position-sticky white"
    style={{
      top: '0',
      zIndex: '999'
    }}>
    <Navigation />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
