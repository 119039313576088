import React from "react"
import { Link } from 'gatsby'
import FooterLogo from "../assets/DiversiTechLogo.svg"

const Footer = () => (
  <footer className="black py-5">
    <div className="container max">
      <div className="row">
        <div className="col d-flex white-text smallest align-items-center">
          <div className="pt-2 pr-3 pr-lg-5 mr-0 mr-md-5"><a href="https://www.diversitech.com/en-US/"><FooterLogo /></a></div>
          <div className="flex-grow-1 text-right text-md-left">
            <span className="text-nowrap d-block d-md-inline-flex">© {new Date().getFullYear()} DiversiTech Corporation</span> <span className="d-none d-md-inline-flex">&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span> <span className="text-nowrap d-block d-md-inline-flex"><Link to="/privacy-policy/">Privacy Policy</Link></span>
          </div>
        </div>
        <div className="col-md-3 text-right white-text smallest align-items-center">US Patents 10,605,505 B2, 20,190,161,658 A1 and 10,514,190.  Other US and foreign patents pending.</div>
      </div>
    </div>
  </footer>
)

export default Footer
