import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import Helmet from "react-helmet"

import Header from "./header"
import Footer from "./footer"
import "./scss/layout.scss"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <Helmet>
        <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=avgtgwfezgnkgwv5chlxlg" async="true"></script>
        <link rel="stylesheet" href="https://use.typekit.net/iim3yvf.css" async="false"></link>
        <link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"></link>
      </Helmet>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
