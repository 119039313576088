import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
// import Nav from 'react-bootstrap/Nav'
import Logo from "../assets/MasterCareLogo.svg"
import Link from "gatsby-link"

const Navigation = () => (
  <Navbar expand="lg">
    <div className="container max">
      <Link to="/"><Navbar.Brand><Logo /></Navbar.Brand></Link>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Link className="nav-link" to="/about/">ABOUT</Link>
        <Link className="nav-link" to="/#section-1">PRODUCTS</Link>
        <Link className="nav-link" to="/contact/">CONTACT</Link>
      </Navbar.Collapse>
    </div>
  </Navbar>
)

export default Navigation